<template>
  <div class="w-full sm:w-600 h-screen">
    <!-- Header -->
    <div
        class="flex justify-between items-center border-b px-4 py-3 sticky top-0 bg-white dark:bg-dim-900 border-l border-r border-gray-200 dark:border-gray-700"
    >
      <!-- Title -->
      <h2 class="text-gray-800 dark:text-gray-100 font-bold font-sm">
        Home
      </h2>
      <!-- /Title -->

      <!-- Custom Timeline -->
      <div>
        <svg
            viewBox="0 0 24 24"
            class="w-5 h-5 text-blue-400"
            fill="currentColor"
        >
          <g>
            <path
                d="M22.772 10.506l-5.618-2.192-2.16-6.5c-.102-.307-.39-.514-.712-.514s-.61.207-.712.513l-2.16 6.5-5.62 2.192c-.287.112-.477.39-.477.7s.19.585.478.698l5.62 2.192 2.16 6.5c.102.306.39.513.712.513s.61-.207.712-.513l2.16-6.5 5.62-2.192c.287-.112.477-.39.477-.7s-.19-.585-.478-.697zm-6.49 2.32c-.208.08-.37.25-.44.46l-1.56 4.695-1.56-4.693c-.07-.21-.23-.38-.438-.462l-4.155-1.62 4.154-1.622c.208-.08.37-.25.44-.462l1.56-4.693 1.56 4.694c.07.212.23.382.438.463l4.155 1.62-4.155 1.622zM6.663 3.812h-1.88V2.05c0-.414-.337-.75-.75-.75s-.75.336-.75.75v1.762H1.5c-.414 0-.75.336-.75.75s.336.75.75.75h1.782v1.762c0 .414.336.75.75.75s.75-.336.75-.75V5.312h1.88c.415 0 .75-.336.75-.75s-.335-.75-.75-.75zm2.535 15.622h-1.1v-1.016c0-.414-.335-.75-.75-.75s-.75.336-.75.75v1.016H5.57c-.414 0-.75.336-.75.75s.336.75.75.75H6.6v1.016c0 .414.335.75.75.75s.75-.336.75-.75v-1.016h1.098c.414 0 .75-.336.75-.75s-.336-.75-.75-.75z"
            ></path>
          </g>
        </svg>
      </div>
      <!-- /Custom Timeline -->
    </div>
    <!-- /Header -->

    <!-- Post Tweet -->
    <form
        @submit.prevent="submitTweet"
        class="border-b border-gray-200 dark:border-dim-200 pb-4 border-l border-r"
    >
      <div class="flex flex-shrink-0 p-4 pb-0">
        <div class="w-12 flex items-top">
          <img
              class="inline-block h-10 w-10 rounded-full"
              src="https://pbs.twimg.com/profile_images/1308769664240160770/AfgzWVE7_normal.jpg"
              alt=""
          />
        </div>
        <div class="w-full p-2">
                <textarea
                    v-model="text"
                    class="dark:text-white text-gray-900 placeholder-gray-400 w-full h-10 bg-transparent border-0 focus:outline-none resize-none"
                    placeholder="What's happening?"
                    required
                ></textarea>
        </div>
      </div>
      <div class="w-full flex items-top p-2 text-white pl-14">
        <a
            href="#"
            class="text-blue-400 hover:bg-blue-50 dark:hover:bg-dim-800 rounded-full p-2"
        >
          <svg viewBox="0 0 24 24" class="w-5 h-5" fill="currentColor">
            <g>
              <path
                  d="M19.75 2H4.25C3.01 2 2 3.01 2 4.25v15.5C2 20.99 3.01 22 4.25 22h15.5c1.24 0 2.25-1.01 2.25-2.25V4.25C22 3.01 20.99 2 19.75 2zM4.25 3.5h15.5c.413 0 .75.337.75.75v9.676l-3.858-3.858c-.14-.14-.33-.22-.53-.22h-.003c-.2 0-.393.08-.532.224l-4.317 4.384-1.813-1.806c-.14-.14-.33-.22-.53-.22-.193-.03-.395.08-.535.227L3.5 17.642V4.25c0-.413.337-.75.75-.75zm-.744 16.28l5.418-5.534 6.282 6.254H4.25c-.402 0-.727-.322-.744-.72zm16.244.72h-2.42l-5.007-4.987 3.792-3.85 4.385 4.384v3.703c0 .413-.337.75-.75.75z"
              ></path>
              <circle cx="8.868" cy="8.309" r="1.542"></circle>
            </g>
          </svg>
        </a>

        <a
            href="#"
            class="text-blue-400 hover:bg-blue-50 dark:hover:bg-dim-800 rounded-full p-2"
        >
          <svg viewBox="0 0 24 24" class="w-5 h-5" fill="currentColor">
            <g>
              <path
                  d="M19 10.5V8.8h-4.4v6.4h1.7v-2h2v-1.7h-2v-1H19zm-7.3-1.7h1.7v6.4h-1.7V8.8zm-3.6 1.6c.4 0 .9.2 1.2.5l1.2-1C9.9 9.2 9 8.8 8.1 8.8c-1.8 0-3.2 1.4-3.2 3.2s1.4 3.2 3.2 3.2c1 0 1.8-.4 2.4-1.1v-2.5H7.7v1.2h1.2v.6c-.2.1-.5.2-.8.2-.9 0-1.6-.7-1.6-1.6 0-.8.7-1.6 1.6-1.6z"
              ></path>
              <path
                  d="M20.5 2.02h-17c-1.24 0-2.25 1.007-2.25 2.247v15.507c0 1.238 1.01 2.246 2.25 2.246h17c1.24 0 2.25-1.008 2.25-2.246V4.267c0-1.24-1.01-2.247-2.25-2.247zm.75 17.754c0 .41-.336.746-.75.746h-17c-.414 0-.75-.336-.75-.746V4.267c0-.412.336-.747.75-.747h17c.414 0 .75.335.75.747v15.507z"
              ></path>
            </g>
          </svg>
        </a>

        <a
            href="#"
            class="text-blue-400 hover:bg-blue-50 dark:hover:bg-dim-800 rounded-full p-2"
        >
          <svg viewBox="0 0 24 24" class="w-5 h-5" fill="currentColor">
            <g>
              <path
                  d="M20.222 9.16h-1.334c.015-.09.028-.182.028-.277V6.57c0-.98-.797-1.777-1.778-1.777H3.5V3.358c0-.414-.336-.75-.75-.75s-.75.336-.75.75V20.83c0 .415.336.75.75.75s.75-.335.75-.75v-1.434h10.556c.98 0 1.778-.797 1.778-1.777v-2.313c0-.095-.014-.187-.028-.278h4.417c.98 0 1.778-.798 1.778-1.778v-2.31c0-.983-.797-1.78-1.778-1.78zM17.14 6.293c.152 0 .277.124.277.277v2.31c0 .154-.125.28-.278.28H3.5V6.29h13.64zm-2.807 9.014v2.312c0 .153-.125.277-.278.277H3.5v-2.868h10.556c.153 0 .277.126.277.28zM20.5 13.25c0 .153-.125.277-.278.277H3.5V10.66h16.722c.153 0 .278.124.278.277v2.313z"
              ></path>
            </g>
          </svg>
        </a>

        <a
            href="#"
            class="text-blue-400 hover:bg-blue-50 dark:hover:bg-dim-800 rounded-full p-2"
        >
          <svg viewBox="0 0 24 24" class="w-5 h-5" fill="currentColor">
            <g>
              <path
                  d="M12 22.75C6.072 22.75 1.25 17.928 1.25 12S6.072 1.25 12 1.25 22.75 6.072 22.75 12 17.928 22.75 12 22.75zm0-20C6.9 2.75 2.75 6.9 2.75 12S6.9 21.25 12 21.25s9.25-4.15 9.25-9.25S17.1 2.75 12 2.75z"
              ></path>
              <path
                  d="M12 17.115c-1.892 0-3.633-.95-4.656-2.544-.224-.348-.123-.81.226-1.035.348-.226.812-.124 1.036.226.747 1.162 2.016 1.855 3.395 1.855s2.648-.693 3.396-1.854c.224-.35.688-.45 1.036-.225.35.224.45.688.226 1.036-1.025 1.594-2.766 2.545-4.658 2.545z"
              ></path>
              <circle cx="14.738" cy="9.458" r="1.478"></circle>
              <circle cx="9.262" cy="9.458" r="1.478"></circle>
            </g>
          </svg>
        </a>

        <a
            href="#"
            class="text-blue-400 hover:bg-blue-50 dark:hover:bg-dim-800 rounded-full p-2"
        >
          <svg viewBox="0 0 24 24" class="w-5 h-5" fill="currentColor">
            <g>
              <path d="M-37.9 18c-.1-.1-.1-.1-.1-.2.1 0 .1.1.1.2z"></path>
              <path
                  d="M-37.9 18c-.1-.1-.1-.1-.1-.2.1 0 .1.1.1.2zM18 2.2h-1.3v-.3c0-.4-.3-.8-.8-.8-.4 0-.8.3-.8.8v.3H7.7v-.3c0-.4-.3-.8-.8-.8-.4 0-.8.3-.8.8v.3H4.8c-1.4 0-2.5 1.1-2.5 2.5v13.1c0 1.4 1.1 2.5 2.5 2.5h2.9c.4 0 .8-.3.8-.8 0-.4-.3-.8-.8-.8H4.8c-.6 0-1-.5-1-1V7.9c0-.3.4-.7 1-.7H18c.6 0 1 .4 1 .7v1.8c0 .4.3.8.8.8.4 0 .8-.3.8-.8v-5c-.1-1.4-1.2-2.5-2.6-2.5zm1 3.7c-.3-.1-.7-.2-1-.2H4.8c-.4 0-.7.1-1 .2V4.7c0-.6.5-1 1-1h1.3v.5c0 .4.3.8.8.8.4 0 .8-.3.8-.8v-.5h7.5v.5c0 .4.3.8.8.8.4 0 .8-.3.8-.8v-.5H18c.6 0 1 .5 1 1v1.2z"
              ></path>
              <path
                  d="M15.5 10.4c-3.4 0-6.2 2.8-6.2 6.2 0 3.4 2.8 6.2 6.2 6.2 3.4 0 6.2-2.8 6.2-6.2 0-3.4-2.8-6.2-6.2-6.2zm0 11c-2.6 0-4.7-2.1-4.7-4.7s2.1-4.7 4.7-4.7 4.7 2.1 4.7 4.7c0 2.5-2.1 4.7-4.7 4.7z"
              ></path>
              <path
                  d="M18.9 18.7c-.1.2-.4.4-.6.4-.1 0-.3 0-.4-.1l-3.1-2v-3c0-.4.3-.8.8-.8.4 0 .8.3.8.8v2.2l2.4 1.5c.2.2.3.6.1 1z"
              ></path>
            </g>
          </svg>
        </a>

        <button
            type="submit"
            class="bg-blue-400 hover:bg-blue-500 text-white rounded-full py-1 px-4 ml-auto mr-1"
        >
          <span class="font-bold text-sm">Tweet</span>
        </button>
      </div>
    </form>

    <!-- /Post Tweet -->

    <!-- Timeline -->

    <!-- New Tweets -->
    <div
        class="border-b border-gray-200 dark:border-dim-200 bg-gray-50 dark:bg-dim-300 py-2 border-l border-r"
    >
      <div
          class="flex flex-shrink-0 items-center justify-center py-4 bg-white dark:bg-dim-900 border-b border-t border-gray-200 dark:border-dim-200 hover:bg-gray-50 dark:hover:bg-dim-300 cursor-pointer transition duration-350 ease-in-out text-blue-400 text-sm"
      >
        Show 9 Tweets
      </div>
    </div>
    <!-- /New Tweets -->

    <Tweet v-for="(tweet, index) in tweets" :tweet="tweet" :key="index"/>

    <!-- Spinner -->
    <div
        class="flex items-center justify-center p-4 border-b border-l border-r border-gray-200 dark:border-gray-700"
    >
      <svg class="w-8 h-8 animate-spin-fast">
        <circle
            cx="16"
            cy="16"
            fill="none"
            r="14"
            stroke-width="4"
            style="stroke: rgb(29, 161, 242); opacity: 0.2"
        ></circle>
        <circle
            cx="16"
            cy="16"
            fill="none"
            r="14"
            stroke-width="4"
            style="
                  stroke: rgb(29, 161, 242);
                  stroke-dasharray: 80;
                  stroke-dashoffset: 60;
                "
        ></circle>
      </svg>
    </div>
    <!-- /Spinner -->

    <!-- /Timeline -->
  </div>
</template>
<script>
import Tweet from "@/components/Tweet.vue";
import {mapActions, mapState} from "pinia";
import {useTweetsStore} from "@/stores/tweets";

export default {
  name: "Middle",
  components: {Tweet},
  async created() {
    await this.fetchTweets();
  },
  data() {
    return {
      text: ''
    }
  },
  computed: {
    ...mapState(useTweetsStore, ['tweets'])
  },
  methods: {
    ...mapActions(useTweetsStore, ['fetchTweets', 'addTweet']),
    async submitTweet() {
      await this.addTweet(this.text)
      this.text = '';
    }
  },

}
</script>